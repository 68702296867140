










import { Component, Vue } from 'vue-property-decorator';

import HeroAlt from '@/components/sections/HeroAlt.vue';
import KeepInTouch from '@/components/sections/KeepInTouch.vue';
import Map from '@/components/sections/Map.vue';

@Component({
  components: {
    HeroAlt,
    KeepInTouch,
    Map
  },
  metaInfo: () => ({
    title: 'Contact Me'
  })
})
export default class Contact extends Vue {
  
}
